aside#featured-properties
{
    margin-top: 6rem;
    margin-bottom: 0;

    > .bxslider
    {
        display: none;
    }

    .bx-wrapper
    {
        border: 0;
        margin-bottom: 0;
    }

    .bx-viewport
    {
        margin: 40px 0 20px;
    }

    .bx-pager
    {
        padding: 0;
        line-height: 1;
        position: static;
    }

    .bx-pager-item
    {
        display: inline-block;
        margin: 0 4px;

        > a
        {
            text-indent: -999vw;
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 10px;
            border: 1px solid darken($colorInverse, 20%);
            background-color: transparent;
            margin: 0;

            &.active
            {
                background-color: darken($colorInverse, 20%);
            }
        }
    }
}

.featured-item
{
    font-family: $altFont;
    text-transform: uppercase;
    margin-bottom: 2em;
    border-bottom: 0;

    .item-photo
    {
        height: 250px;
        background-size: cover;
        background-position: center center;
        margin-bottom: 1em;
        position: relative;
        overflow: hidden;

        > .item-description
        {
            position: absolute;
            bottom: 0;
            width: 100%;
            background-color: rgba(black, 0.8);
            color: $colorInverse;
            padding: 20px;
            font-size: 10px;
            letter-spacing: 1px;
            transform: translateY(100%);
            transition: transform ease-in-out 0.25s;
            will-change: transform;
        }


        > div.item-attributes
        {
            background-color: rgba($colorAccent, 0.8);
            color: $colorInverse;
            position: absolute;
            top: 20px;
            right: 0;

            > .item-attribute-icons > div
            {
                display: block;
                margin: 6px 12px;
                font-size: 10px;
            }

            i
            {
                display: inline-block;
                font-size: 1.3333em;
                margin-left: 0.3333em;
            }
        }
    }

    h4
    {
        color: $colorAccent;
        margin: 0;
        font-size: 18px;
        letter-spacing: 1px;
    }

    p
    {
        margin: 0;
        font-weight: bold;
        color: $colorBaseMid;
        letter-spacing: 1px;
    }

    &:hover
    {
        .item-photo > .item-description
        {
            transform: translateY(0);
        }
    }
}
