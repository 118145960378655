@import "inc/variables";
@import "inc/bootstrap/defer";

$hamburger-layer-color: $colorInverse;

$fa-css-prefix: la;
@import "~font-awesome/scss/font-awesome";
@import "~vesper-icons/scss/vesper-icons";
@import "~hamburgers/_sass/hamburgers/hamburgers";
@import "~bxslider/dist/jquery.bxslider";
@import "~jssocials/dist/jssocials.css";
@import "~jssocials/dist/jssocials-theme-flat.css";

@import "inc/colorbox";
// @import "inc/la-fix";
@import "inc/bxslider";

@import "parts/autocomplete";

@import "parts/icon-button";
@import "parts/social";
@import "parts/slider";
@import "parts/maps";
@import "parts/featured-property";
@import "parts/parallax";
@import "parts/accreditations";
@import "parts/footer";
@import "parts/pagination";
@import "parts/sitemap";