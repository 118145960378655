@import "../variables";

$primary: $colorOrange;
$secondary: $colorBaseMid;
$dark: $colorBaseMid;

$font-weight-normal: normal;
$font-family-sans-serif: $mainFont;
$headings-font-family: $altFont;
$headings-font-weight: 700;
$small-font-size: #{(2rem / 3)};

$input-btn-font-size: 0.875rem;
$input-group-addon-bg: #fff;
$input-border-color: $colorLightGrey;
$input-line-height: 2;

$border-radius: 0;

$card-spacer-y: 2rem;
$card-spacer-x: 2rem;

$grid-breakpoints: ( xs: 0, sm: 480px, md: 768px, lg: 992px, xl: 1200px );
$container-max-widths: ( sm: 450px, md: 750px, lg: 940px, xl: 1140px );

$nav-tabs-link-active-bg: $colorBaseMid;
$nav-tabs-link-active-color: $colorInverse;

$form-grid-gutter-width: 30px;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";