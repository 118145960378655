a.sitemap-expand
{
    text-decoration: none;
    margin-left: .5rem;
    border-bottom: 0;

    &:before
    {
        content: "\f106";
        @include line-awesome();
    }

    &.collapsed:before
    {
        content: "\f107"
    }
}
