$mainFont: 'Lato', sans-serif;
$altFont: 'Montserrat', sans-serif;

$colorOrange: #F58220;
$colorGrey: #838991;
$colorLightGrey: #eee;
$colorDarkGrey: #231F20;

$colorInverse: white;
$colorBaseLight: $colorLightGrey;
$colorBaseMid: $colorDarkGrey;
$colorBaseDark: black;
$colorAccent: $colorOrange;
$colorAlternate: $colorGrey;

$textSizeBase: 16px;
$baseShadow: 0 0 5px 0.5px rgba(black, 0.2);
$borderSize: 3px;

$sectionPadding: 4rem;

/* Bootstrap overrides */
$line-height-base: 1.75;

@mixin uppercaseText()
{
    text-transform: uppercase;
}

@mixin arrowButton()
{
    display: inline-block;
    background-color: $colorBaseLight;
    color: $colorBaseMid;
    font-size: .85rem;
    font-style: normal;
    border: 0;
    padding: 1em 4.5em 1em 1.5em;
    line-height: 1;
    position: relative;
    text-decoration: none;

    &:after
    {
        content: "\f054";
        @include line-awesome();
        display: block;
        padding: 1em;
        background-color: $colorAccent;
        color: $colorInverse;
        position: absolute;
        right: 0;
        top: 0;
        box-sizing: content-box;
    }
}

@function encodecolor($string)
{
    @if type-of($string) == 'color'
    {
        $hex: str-slice(ie-hex-str($string), 4);
        $string: unquote("#{$hex}");
    }

    $string: '%23' + $string;
    @return $string;
}

@mixin line-awesome()
{
    font-family: 'FontAwesome';
}