footer#page-footer
{
    background-color: $colorDarkGrey;
    color: $colorInverse;
    font-size: 14px;
    line-height: (30px / 14px) * 1;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    text-align: left;

    a
    {
        font-weight: bold;
    }

    h3
    {
        font-size: 18px;
        line-height: 1;
        letter-spacing: 5px;
        margin-top: 0;
        border-bottom: 3px solid $colorAccent;
        display: inline-block;
        padding-bottom: 8px;
        margin-bottom: 1.5em;
    }

    > div#footer-map
    {
        > .container-fluid > .row > div
        {
            padding: 0;
        }

        iframe
        {
            width: 100%;
            height: 600px;
            border: 0;

            @include media-breakpoint-down(sm)
            {
                max-height: 80vh;
            }
        }
    }

    p
    {
        margin-bottom: 30px;

        &:last-child
        {
            margin-bottom: 0;
        }

        &.footer-contact
        {
            text-transform: none;
            // margin-bottom: 1px; // hack to get around sub-pixel positioning
        }
    }

    div.footer-social
    {
        font-size: 21px;

        @include media-breakpoint-up(md)
        {
            margin-bottom: 102px;
        }
    }

    @include media-breakpoint-down(sm)
    {
        text-align: center;

        h3,
        > div#footer-map
        {
            margin-top: 50px;
        }
    }

    @include media-breakpoint-up(md)
    {
        height: 600px;

        > section#footer-content
        {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

footer#page-copyright
{
    font-size: .875rem;
    background-color: $colorLightGrey;
    padding: 1.25rem 0;
    text-align: left;
    font-weight: normal;
    color: $colorBaseMid;

    @include media-breakpoint-down(sm)
    {
        text-align: center;
    }

    .list-inline-item
    {
        margin-right: 0.333rem;

        &:last-child
        {
            margin-right: 0;

            &:after
            {
                content: none;
                margin-left: 0;
            }
        }

        &:after
        {
            content: "|";
            display: inline-block;
            margin-left: 0.333rem;
        }
    }

    p, ul
    {
        margin-bottom: 0;
    }

    a
    {
        text-decoration: none;
        color: $colorBaseMid;
        border-bottom: 0;
        font-weight: normal;

        &:focus,
        &:hover
        {
            border-bottom: 1px solid $colorBaseMid;
        }
    }

    .copyright-text
    {
        font-size: .8rem;

        @include media-breakpoint-down(md)
        {
            margin-top: 1em;
        }
    }
}
